<template>
  <v-container fluid>
    <v-card aut-add-form>
      <v-card-title> Add a new Form </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                aut-field-form-name
                clearable
                required
                :rules="formNameRules"
                v-model="formName"
                label="Name *"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-add-form-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isButtonDisabled"
          @click="addPage"
          aut-action-add-page
        >
          {{ buttonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { navigateTo, rules } from "@/util.js";
const debug = require("debug")("atman.components.create_form"); // eslint-disable-line

export default {
  name: "CreateForm",
  data() {
    const context = `/public/forms`;

    const data = {
      formName: "",
      display: true,
      context,
      formNameRules: [rules.mandatory(), rules.internal_name()],
    };
    debug(`data `, data);
    return data;
  },
  props: {
    contextObj: {
      type: Object,
    },
  },
  computed: {
    path() {
      let path = `${this.context}/${this.formName || "<form_name>"}?page=form`;
      return path;
    },
    isButtonDisabled() {
      return !this.context || !this.formName;
    },
    buttonLabel() {
      return "Add Form";
    },
  },
  methods: {
    addPage() {
      const path = encodeURIComponent(this.path);
      navigateTo(this.$router, `/editor_page?page=${path}&operation=create`);// eslint-disable-line
    },
  },
};
</script>
